<template>
  <v-container class="account" fill-height fluid>
     <v-row align="center" justify="center" v-if="user">
        <v-col order="2" order-md="0" cols="12" sm="3" md="2" class="contact-panel">
          <div class="your-account text-right d-none d-md-block">
            Your<br>
            Account
          </div>
          <div class="contact-spacer d-none d-sm-block"></div>
          <div class="contact-account">
            <v-btn tile color="primary" :height="64" :loading="disabled" class="d-md-none mobile-save-button" @click="save()">
              Save
            </v-btn>
            <v-btn v-if="mobile" tile color="primary darken-2" @click="logout()" :height="64" class="my-4 d-md-none mobile-logout-button">
              Log Out
            </v-btn>
            <div class="text-right contact-support cursor-pointer" @click="contactSupportDialog = true">
              Contact Support
            </div>
            <div class="text-right delete-account cursor-pointer" @click="deleteAccountDialog = true">
              Delete Account
            </div>
          </div>
        </v-col>
        <v-col order="1" order-md="0" cols="12" sm="4" md="2" class="user-panel pa-4">
          <imageUploader class="profile-image-uploader" ref="userImage" v-model="user.imageId" :width="176" :height="176" :disabled="disabled" :url="user.image" placeholder="/icons/user.png" :maxSize="1048576"/>
          <div class="user-account-fields px-4">
            <serverValidationField ref="usernameField" v-model="user.username" label="Username" fieldClass="edit-account-fields" :height="40" :disabled="disabled" :acceptOriginalValue="true"
              endpoint="Auth/usernameAvailable" :dataFunction="usernameDataFunction" :responseFunction="usernameResponseFunction" :messages="usernameFailReason" textColor="black" appendIcon="mdi-pencil"/>
            
            <serverValidationField ref="emailField" v-model="user.email" label="Email" fieldClass="edit-account-fields" :height="40" :disabled="disabled" :acceptOriginalValue="true"
              endpoint="Auth/emailAvailable" :dataFunction="emailDataFunction" :responseFunction="emailResponseFunction" :messages="emailFailReason" :inputDelay="10" textColor="black" appendIcon="mdi-pencil"/>

            <div class="cursor-pointer change-password-button pa-1" @click="resetPassword">
              <div class="pa-2">
                Reset Password
                <v-icon color="black">
                  mdi-pencil
                </v-icon>
              </div>
            </div>
          </div>
        </v-col>
        <v-col order="0" order-md="0" cols="12" sm="4" md="2" class="payment-panel text-center" v-if="user && activeSubscription">
          {{ accountDetails.paymentTitle }}
          <div class="price-box">
            <div class="coupon-text" v-if="isCoupon">
              <div>{{accountDetails.priceLabel}}</div>
            </div>
            <div class="plan-price" v-else>
              <div v-bind:style="{ 'font-size': accountDetails.priceSize }">{{accountDetails.priceLabel}}</div>
              <sup class="plan-super-text">{{accountDetails.superText}}</sup>
            </div>
            <div class="plan-interval">{{ accountDetails.priceSubtext }}</div>
          </div>
          <div class="payment-method py-2" v-if="paymentMethod">
            Using {{ paymentMethod }}
          </div>
          <div class="cancel-subscription py-2 cursor-pointer" v-if="canCancel" @click="cancelSubscription">
            Cancel My Subscription
          </div>
          <div class="upgrade-subscription py-2 cursor-pointer" v-if="canUpgrade" @click="upgradeAccount">
            Upgrade my account
          </div>
          <div v-if="activeSubscription.legacy" class="legacy-info">
            <v-icon color="primary" style="margin-left: -24px; margin-top: -56px;">mdi-information-outline</v-icon>
            You are on a Disrupt<span class="primary--text">+</span> Legacy account.<br>
            If cancelled, you will not be able to <br>
            re-subscribe at this monthly price.
          </div>
          <v-btn tile color="primary" :loading="disabled" class="save-button" @click="save()">
            Save
          </v-btn>
        </v-col>
        <v-col cols="0" md="1">
        </v-col>
    </v-row>
    <v-dialog v-model="cancelSubscriptionDialog" content-class="flat-dialog" width="300" overlay-color="black" overlay-opacity="0.8">
      <v-card>
        <v-card-title>
          Cancel subscription?
        </v-card-title>
        <v-card-text class="subscription-dialog-text">
          You can still access content until your plan expires
        </v-card-text>
        <v-card-actions class="subscription-dialog-actions">
          <v-btn class="block-button" flat @click="confirmCancelSubscription" color="red accent-4">
            Yes, cancel
          </v-btn>
          <v-btn class="block-button" flat @click="cancelSubscriptionDialog = false">
            Nevermind
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="contactSupportDialog" content-class="flat-dialog" width="400" overlay-color="black" overlay-opacity="0.8">
      <v-card>
        <v-card-title>
        </v-card-title>
        <v-card-text class="contact-dialog-text">
          <v-row class="py-4">
            <v-col cols="12" class="text-center">
              <v-icon x-large>
                mdi-email
              </v-icon>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="text-center contact-email">
              hello@studiodisrupt.tv
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="subscription-dialog-actions">
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteAccountDialog" content-class="flat-dialog" width="350" overlay-color="black" overlay-opacity="0.8">
      <v-card>
        <v-card-title class="delete-account-title">
          {{ canCancel ? 'You have an active subscription' : 'Delete account?' }}
        </v-card-title>
        <v-card-text class="delete-account-text pt-4">
          {{ canCancel ? 'Please cancel before deleting your account' : '(This cannot be reversed)' }}
        </v-card-text>
        <v-card-actions class="subscription-dialog-actions">
          <v-btn v-if="!canCancel" class="block-button" flat @click="deleteAccount" color="red accent-4">
            Yes, delete
          </v-btn>
          <v-btn v-if="!canCancel" class="block-button" flat @click="deleteAccountDialog = false">
            Nevermind
          </v-btn>
          <v-btn v-if="canCancel" class="block-button" flat @click="deleteAccountDialog = false">
            Return to account
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { Api } from '../js/api';
import { EventBus } from '../eventBus';

import moment from 'moment'

import imageUploader from '../components/global/imageUploader'
import serverValidationField from '../components/global/serverValidationField'

export default {
  name: 'Account',
  components: {
    imageUploader,
    serverValidationField
  },
  data: () => ({
    usernameFailReason: null,
    emailFailReason: null,
    disabled: false,
    user: null,
    dirty: false,
    cancelSubscriptionDialog: false,
    contactSupportDialog: false,
    deleteAccountDialog: false
  }),
  computed: {
    activeSubscription () {
      if (this.user && this.user.subscriptions) {
        return this.user.subscriptions.find(s => s.active);
      }
      return null;
    },
    isCoupon () {
      if (this.activeSubscription) {
        if (this.activeSubscription.planType == 'coupon') {
          return true
        }
      }
      return false;
    },
    accountDetails () {
      let details = {
        paymentTitle: '',
        priceLabel: '',
        priceSize: '96px',
        superText: '',
        priceSubtext: ''
      }
      if (this.activeSubscription) {
        if (this.isInvestor) {
          details.paymentTitle = 'Exclusive'
          details.priceLabel = 'Investor'
          details.priceSize = '48px'
          details.superText = ''
          details.priceSubtext = 'for life'
        } else if (this.activeSubscription.planType == 'free') {
          details.paymentTitle = 'You are on the limited'
          details.priceLabel = 'Free'
          details.superText = ''
          details.priceSubtext = 'plan'
        } else if (this.activeSubscription.planType == 'coupon') {
          details.paymentTitle = 'You are using an exclusive'
          details.priceLabel = 'Coupon'
          details.superText = ''
          details.priceSubtext = this.daysToExpiry + ' days remain on your pass'
        } else if (this.activeSubscription.planType == 'month') {
          details.paymentTitle = 'You have paid'
          details.priceLabel = '$' + this.activeSubscription.price
          details.superText = ''
          details.priceSubtext = this.daysToExpiry + ' days remain on your pass'
        } else if (this.activeSubscription.planType == 'monthly' || this.activeSubscription.planType == 'yearly') {
          details.paymentTitle = ''
          details.priceLabel = '$' + this.activeSubscription.price
          details.superText = ''
          details.priceSubtext = ''

          if (this.activeSubscription.status == 'Active') {
            details.paymentTitle = 'You are currently paying';
          } else {
            details.paymentTitle = this.activeSubscription.status;
          }

          if (this.activeSubscription.planType == 'monthly') {
            details.superText = '/mo';
          }
          if (this.activeSubscription.planType == 'yearly') {
            details.superText = '/yr'
          }

          if (this.activeSubscription.status == 'Active') {
            details.priceSubtext = 'next charge on ' + moment(this.expiryDate).format('MMM Do');
          } else {
            details.priceSubtext = this.daysToExpiry + ' days remain on your pass';
          }
        }
      }
      return details;
    },
    paymentMethod () {
       if (this.activeSubscription) {
        if (this.activeSubscription.planType == 'monthly' || this.activeSubscription.planType == 'yearly') {
          let label = this.activeSubscription.paymentType;
          label = label.charAt(0).toUpperCase() + label.slice(1);
          return label;
        }
      }
      return null;
    },
    canCancel () {
      if ((this.activeSubscription.planType == 'monthly' || this.activeSubscription.planType == 'yearly')
        && this.activeSubscription.status == 'Active') {
        return true;
      }
      return false;
    },
    isInvestor () {
      if(this.user && this.user.role == 'Investor') {
        return true;
      }
      return false;
    },
    canUpgrade () {
      if(this.isInvestor) {
        return false;
      }
      if(this.activeSubscription && this.activeSubscription.planType == 'free') {
        return true;
      }
      return false;
    },
    expiryDate () {
      if (this.activeSubscription && this.activeSubscription.expires) {
        return this.activeSubscription.expires;
      }
      return null;
    },
    daysToExpiry () {
      if (this.expiryDate) {
        let now = new Date();
        let expiry = new Date(this.expiryDate);

        let days = Math.round((expiry-now)/(1000*60*60*24));
        return days;
      }
      return null;
    },
    mobile () {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm;
    }
  },
  methods: {
    usernameDataFunction: function (username) {
      let data = {
        username: username
      }
      return data;
    },
    emailDataFunction: function (email) {
      let data = {
        email: email
      }
      return data;
    },
    usernameResponseFunction: function (res) {
      if (res.data === true) {
        this.usernameFailReason = '';
      } else {
        this.usernameFailReason = res.data;
      }
      return res.valid;
    },
    emailResponseFunction: function (res) {
      if (res.data === true) {
        this.emailFailReason = '';
      } else {
        this.emailFailReason = res.data;
      }
      return res.valid;
    },
    save: function () {
      let self = this;

      if (!this.$refs.usernameField.checkValid) {
        return;
      }
      if (!this.$refs.emailField.checkValid) {
        return;
      }

      if (self.$refs.userImage.file) {
        // upload the file first
        self.$refs.userImage.upload().then(function () {
          self.saveProfile();
        })
      } else {
        self.saveProfile();
      }
    },
    saveProfile: function () {
      let self = this;
      let data = {
        username: self.user.username,
        email: self.user.email,
        profileImageId: self.user.imageId
      }

      self.disabled = true;
      Api.post('User/update', data).then(function (res) {
        if (res.valid) {
          EventBus.$emit('alert', {
            text: 'Profile updated',
            alertColor: 'green',
            alertIcon: 'success'
          })
          self.$refs.usernameField.originalValue = self.user.username;
        } else {
          EventBus.$emit('alert', {
            text: 'Could not update profile',
            alertColor: '#a70505'
          })
        }
        self.$store.dispatch('getUser').then(function () {
          self.disabled = false;
        });
      })
    },
    cancelSubscription: function () {
      this.cancelSubscriptionDialog = true;
    },
    confirmCancelSubscription: function () {
      let scope = this;
      scope.disabled = true;

      Api.post('Subscription/cancelSubscription').then(function (res) {
        if (res.valid) {
          scope.cancelSubscriptionDialog = false;
          scope.getUser();
        } else {
          // 
        }
        scope.disabled = true;
      })
    },
    deleteAccount: function () {
      let self = this;
      Api.post('Auth/deleteAccount').then(function (res) {
        if (res.valid) {
          self.$store.commit('setUser', null);
          self.$store.commit('setIsLoggedIn', false);
          self.navigate('/');
        }
      })
    },
    upgradeAccount: function () {
      this.$router.push('/upgrade');
    },
    getUser: function () {
      let self = this;

      Api.get('User').then(function (res) {
        self.user = res.data;
      });
    },
    logout: function () {
      let self = this;
      Api.post('Auth/logout', null).then(function () {
        self.$store.commit('setUser', null);
        self.$store.commit('setIsLoggedIn', false);
        self.navigate('/');
      })
    },
    resetPassword: function () {
      this.navigate('./forgotPassword');
    },
    navigate: function (path) {
      this.$router.push(path).catch(()=>{});
    },
  },
  mounted () {
    this.getUser();
  }
}
</script>

<style lang="scss" scoped>
.user-panel,
.payment-panel {
  height: 500px; 
}
.user-panel {
  background-color: #232323;
}
.payment-panel {
  background-color: #1c1c1c;
  padding: 16px 0;
  font-size: 18px;
  position: relative;
}

.your-account {
  font-size: 48px;
  height: 160px;
}
.contact-spacer {
  height: calc(500px - 210px);
}
.contact-account {
  height: 50px;
}

.profile-image-uploader {
  padding-bottom: 64px !important;
}

.price-box {
  width: 100% !important;
  height: 180px !important;
  margin: 0px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 0px;
}
.plan-price {
  font-size: 96px;
  width: 100%;
}
.coupon-text {
  font-size: 64px;
}
.plan-super-text {
  font-size: 24px;
  top: -2em;
}
.plan-interval {
  font-size: 16px;
  letter-spacing: 0px;
  width: 100%;
}
.payment-method {
  background-color: #3f3f3f;
}
.cancel-subscription {
  background-color: #2d2d2d;
}
.upgrade-subscription {
  background-color: #2d2d2d;
  border: 4px solid var(--v-primary-darken2);
}
.save-button {
  position: absolute;
  right: 0;
  bottom: 0;
}
.subscription-dialog-text {
  height: 100px;
}
.contact-dialog-text {
  height: 200px;
}
.contact-email { 
  color: white;
  font-size: 24px;
}

.delete-account-title {
  color: white !important;
  font-size: 28px !important;
  text-align: center !important;
  display: block !important;
  word-break: break-word;
}
.delete-account-text {
  color: white !important;
  font-size: 16px;
  text-align: center;
  height: 100px;
}
.subscription-dialog-actions {
  display: block;
  padding: 0px !important;
  margin: 0px !important;
}
.block-button {
  height: 100px !important;
  width: 100%;
  display: block;
  margin: 0px !important;
  padding: 0px !important;
  border-radius: 0px !important;
  text-transform: inherit;
  font-size: 24px;
}

.mobile-logout-button,
.mobile-save-button {
  width: 100%;
}

.disrupt-mobile .account {
  margin-bottom: 54px;
}

.disrupt-mobile .contact-panel {
  padding: 0px;
}
.disrupt-mobile .payment-panel {
  padding-top: 64px;
}

.disrupt-mobile .user-panel {
  background-color: initial;
}
.disrupt-mobile .payment-panel {
  min-height: 100px;
  height: initial;
  background-color: initial;
}

.disrupt-mobile .contact-account {
  height: 300px;
}

.disrupt-mobile .contact-support,
.disrupt-mobile .delete-account {
  height: 64px;
  padding: 20px;
  text-align: center !important;
}

.disrupt-mobile .save-button {
  display: none;
}

.legacy-info {
  font-size: 14px;
  border: 4px solid var(--v-primary-base);
  border-radius: 8px;
  margin: 24px 20px;
  text-align: left;
  padding: 8px;
}

.change-password-button {
  color: black;
  background-color: white;
  height: 48px;
}
</style>
<style lang="scss">
.edit-account-fields .v-input__slot{
  background-color: white !important;
  border-radius: 0px !important;
}
.edit-account-fields input {
  color: black !important;
  padding-left: 0px; 
}
.edit-account-fields .v-label {
  color: black !important;
  padding-left: 0px; 
}
.flat-dialog .v-card,
.flat-dialog {
  border-radius: 0px !important;
}
</style>
